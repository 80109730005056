import { PageProps } from "gatsby"
import React from "react"

import Seo from "../components/Seo"
import { MarginContainer } from "./agb"

const WiderrufsPage: React.FC<PageProps> = () => {
  return (
    <MarginContainer>
      <Seo title="Widerrufsbelehrung" />
      <h3>RÜCKSENDUNGEN</h3>
      <p>
        Unser Rückgaberecht gilt für 14 Tage. Wenn Ihr Kauf mehr als 14 Tage
        zurück liegt, können wir Ihnen leider keine Rückerstattung und keinen
        Umtausch anbieten.
      </p>
      <p>
        Um für eine Rücksendung infrage zu kommen, muss Ihr Artikel unbenutzt
        und in demselben Zustand sein, in dem Sie ihn erhalten haben. Der
        Artikel muss sich zudem in der Originalverpackung befinden.
      </p>

      <p>
        Vom Widerruf ausgeschlossen sind nach § 312 d Abs. 4 Nr. 1 BGB solche
        gelieferten Waren, die nach Kundenspezifikationen angefertigt werden
        oder eindeutig auf persönliche Bedürfnisse zugeschnitten sind.
      </p>

      <h3>Rückerstattungen</h3>
      <p>
        Wenn wir Ihre Rücksendung erhalten und kontrolliert haben, senden wir
        Ihnen eine E-Mail zu, um Sie zu informieren, dass wir den von Ihnen
        zurückgegebenen Artikel erhalten haben. Wir werden Sie auch über die
        Genehmigung oder Ablehnung Ihrer Rückerstattung informieren.
      </p>
      <p>
        Wenn Ihre Rückerstattung genehmigt wurde, wird sie bearbeitet. Nach
        einigen Tagen erfolgt dann automatisch eine Gutschrift auf Ihre
        Kreditkarte oder die ursprüngliche Zahlungsmethode.
      </p>
      <h3>Verspätete oder fehlende Rückerstattungen</h3>
      <p>
        Wenn Sie noch keine Rückerstattung erhalten haben, überprüfen Sie
        zunächst noch einmal Ihr Bankkonto. Kontaktieren Sie anschließend Ihr
        Kreditkartenunternehmen. Es kann einige Zeit dauern, bis Ihre
        Rückerstattung gutgeschrieben wird.
      </p>
      <p>
        Wenden Sie sich als Nächstes an Ihre Bank. Es gibt häufig eine
        Bearbeitungszeit, bis eine Rückerstattung auf Ihr Konto gebucht wird.
      </p>
      <p>
        Wenn Sie all dies getan haben und noch immer keine Rückerstattung
        erhalten haben, kontaktieren Sie uns bitte unter info@hochwerben.de.
      </p>
      <h3>Umtausch</h3>
      <p>
        Ein Umtausch wird nur für defekte oder beschädigte Artikel gewährt. Wenn
        Sie einen Artikel gegen den gleichen Artikel umtauschen möchten, senden
        Sie uns eine E-Mail an info@hochwerben.de und schicken Sie den Artikel
        an: AtlasAvia GmbH, Nordendstr. 82-84, 64546 Mörfelden-Walldorf,
        Deutschland.
      </p>
      <h3>Versand</h3>
      <p>
        Um Ihr Produkt zurückzugeben, sollten Sie Ihr Produkt an: AtlasAvia
        GmbH, Nordendstr. 82-84, 64546 Mörfelden-Walldorf, Deutschland senden.
      </p>
      <p>
        Sie müssen die Versandkosten für die Rücksendung Ihres Artikels selbst
        tragen. Versandkosten können nicht erstattet werden. Wenn Sie eine
        Rückerstattung erhalten, werden die Versandkosten für die Rücksendung
        von Ihrer Rückerstattung abgezogen.
      </p>
      <p>
        Die Zeit, die es dauert, bis der umgetauschte Artikel bei Ihnen
        eintrifft, kann je nach Land, in dem Sie wohnhaft sind, variieren.
      </p>
      <p>
        Wenn Sie einen Artikel im Wert von über 75 € verschicken, sollten Sie
        einen Versanddienst mit Sendungsverfolgung oder den Abschluss einer
        Versandversicherung in Betracht ziehen. Wir können nicht garantieren,
        dass wir den von Ihnen zurückgegebenen Artikel erhalten werden.
      </p>

      <h3>Muster-Widerrufsformular</h3>

      <p>
        Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
        Formular aus und senden Sie es zurück.
      </p>
      <p>
        An:
        <ul>
          <li>AtlasAvia GmbH</li>
          <li>Nordendstr. 82-84</li>
          <li>64546 Mörfelden-Walldorf</li>
          <li>bestellung@hochwerben.de</li>
          <li>
            Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
            Vertrag über den Kauf der folgenden Waren (Bestellnummer)
          </li>
          <li>Bestellt am (*)/erhalten am (*)</li>
          <li>Name des/der Verbraucher(s)</li>
          <li>Anschrift des/der Verbraucher(s)</li>
          <li>
            Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
          </li>
          <li>Datum</li>
        </ul>
        (*) Unzutreffendes streichen.
      </p>
      <p>Stand: Mai 2022</p>
    </MarginContainer>
  )
}

export default WiderrufsPage
